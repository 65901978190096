<style lang="scss">
#login-client {
  .text-info {
    cursor: pointer;
  }
}
</style>
<template>
  <div id="login-client">
    <div class="container">
      <div id="login-row" class="row justify-content-center align-items-center">
        <div id="login-column card" class="col-md-6 card mt-40">
          <div id="login-box" class="col-md-12 card-body">
            <form
              id="login-form"
              class="form"
              @submit.stop.prevent="onSubmit"
              v-if="!isSend"
            >
              <!-- <h3 class="text-center text-info">
                <img
                  :src="
                    getServiceProviderData.image == null
                      ? getServiceProviderData.logo
                      : getServiceProviderData.image
                  "
                  style="width:25%"
                  alt="logo"
                />
              </h3> -->
              <h4 class="text-center mt-5">Reset your password</h4>
              <hr />
              <div
                class="alert alert-danger"
                role="alert"
                v-if="errorMsg != ''"
              >
                {{ errorMsg }}
              </div>
              <div class="form-group">
                <label for="email" class="text-dark"
                  >Enter your verified email address to send you a password
                  reset link.</label
                >

                <input
                  type="email"
                  placeholder="Email Address"
                  v-model="email"
                  class="form-control"
                  :class="
                    errors['email'] && email == '' ? 'border border-danger' : ''
                  "
                />
                <span v-if="errors['email'] && email == ''" class="text-danger">
                  {{ errors["email"] }}
                </span>
              </div>

              <div style="display:inline-flex;width:100%">
                <a
                  @click="goBackToLoginPage()"
                  class="btn btn-danger"
                  style="width:49%;margin-right:10px"
                >
                  Cancel
                </a>
                <button
                  style="width:49%"
                  ref="kt_password_reset"
                  class="btn btn-info btn-md mt-0"
                >
                  Reset
                </button>
              </div>
            </form>
            <div v-else>
              <!-- <h3 class="text-center text-info">
                <img
                  :src="
                    getServiceProviderData.image == null
                      ? getServiceProviderData.logo
                      : getServiceProviderData.image
                  "
                  style="width:25%"
                  alt="logo"
                />
              </h3> -->
              <h4 class="text-center mt-5">Reset your password</h4>
              <hr />
              <p>
                Check your email for a link to reset your password. If it
                doesn’t appear within a few minutes, check your spam folder.
              </p>
              <button
                @click="goBackToLoginPage()"
                class="btn btn-info"
                style="width:100%"
              >
                Return to login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RESET_USER_PASSWORD } from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
// import { useReCaptcha } from "vue-recaptcha-v3";
export default {
  // setup() {
  //   const {recaptchaLoaded } = useReCaptcha();

  //   const recaptcha = async () => {
  //     // (optional) Wait until recaptcha has been loaded.
  //     await recaptchaLoaded();

  //     // Execute reCAPTCHA with action "login".

  //   };

  //   return {
  //     recaptcha
  //   };
  // },
  data() {
    return {
      // Remove this dummy login info
      errorMsg: "",
      email: "",
      showPasswordIcon: false,
      password_type: "password",
      client_slug: "",
      errors: [],
      isSend: false
    };
  },
  created() {},
  computed: {
    ...mapGetters(["getServiceProviderData"])
  },
  methods: {
    goBackToLoginPage() {
      this.$router.push({
        name: "client.login"
      });
    },
    onSubmit() {
      this.errorMsg = "";
      var client_slug = "";
      if (this.email == "") {
        this.errors.email = "This field is required";
      } else {
        // s spinner to submit button
        const submitButton = this.$refs["kt_password_reset"];
        submitButton.disabled = true;
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        this.$store
          .dispatch(RESET_USER_PASSWORD, {
            email: this.email,
            client_slug: client_slug
          })
          .then(() => {
            this.isSend = true;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
          })
          .catch(err => {
            this.errorMsg = err.email;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.disabled = false;
          });
      }
    }
  }
};
</script>
